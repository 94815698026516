import React from "react";
import { Link } from "gatsby";
import select from "./utils";
import { FormattedMessage } from "react-intl";
import menuTree from "../data/menuTree";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaVimeo,
  FaLinkedin,
} from "react-icons/fa";
import Copyright from "./Copyright";
import ScrollToTop from "./ScrollToTop";
import logo from "../img/Walter_Perdan_visual_artist_logo.svg";

const Footer = class extends React.Component {
  render() {
    const props = this.props;
    const sel = select(props.langKey);
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          <img
            src={logo}
            alt="Walter Perdan visual artist logo"
            style={{ width: "14em", height: "5em" }}
          />
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to={"/" + props.langKey} className="navbar-item">
                        <FormattedMessage id="home" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="navbar-item"
                        to={
                          "/" + props.langKey + "/" + menuTree.shop[sel] + "/"
                        }
                      >
                        <FormattedMessage id="shop" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="navbar-item"
                        to={
                          "/" +
                          props.langKey +
                          "/" +
                          menuTree.artworks[sel] +
                          "/"
                        }
                      >
                        <FormattedMessage id="artworks" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="navbar-item"
                        to={
                          "/" +
                          props.langKey +
                          "/" +
                          menuTree.biography[sel] +
                          "/"
                        }
                      >
                        <FormattedMessage id="biography" />
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                    <li>
                      <Link
                        className="navbar-item"
                        to={
                          "/" + props.langKey + "/" + menuTree.blog[sel] + "/"
                        }
                      >
                        <FormattedMessage id="blog" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="navbar-item"
                        to={
                          "/" +
                          props.langKey +
                          "/" +
                          menuTree.statement[sel] +
                          "/"
                        }
                      >
                        <FormattedMessage id="statement" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="navbar-item"
                        to={
                          "/" +
                          props.langKey +
                          "/" +
                          menuTree.contact[sel] +
                          "/"
                        }
                      >
                        <FormattedMessage id="contact" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="navbar-item"
                        to={"/" + props.langKey + "/privacy-policy/"}
                      >
                        Privacy policy
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4 social">
                <a
                  title="facebook"
                  href="https://www.facebook.com/perdanwalter/"
                >
                  <FaFacebook className="facebook-icon" size="2em" />
                </a>
                <a title="twitter" href="https://www.twitter.com/kalwalt/">
                  <FaTwitter className="twitter-icon" size="2em" />
                </a>
                <a
                  title="instagram"
                  href="https://www.instagram.com/walterperdan/"
                >
                  <FaInstagram className="instagram-icon" size="2em" />
                </a>
                <a title="vimeo" href="https://vimeo.com/walterperdan/">
                  <FaVimeo className="vimeo-icon" size="2em" />
                </a>
                <a
                  title="linkedin"
                  href="https://www.linkedin.com/in/walter-perdan/"
                >
                  <FaLinkedin className="linkedin-icon" size="2em" />
                </a>
              </div>
            </div>
          </div>
          <Copyright />
        </div>
        <ScrollToTop />
      </footer>
    );
  }
};

export default Footer;
