module.exports = {
  selectLanguage: "Seleziona la lingua",
  tags: "Tags",
  "tags.allTagsLink": "Sfoglia tutti i tags",
  "tags.intro": "Qui puoi trovare tutti i tags",
  "tags.blog.intro": "Qui puoi trovare tutti i tags del blog",
  "tags.pages.intro": "Qui puoi trovare tutti i tags delle pagine",
  "tags.nPostsTaggedWith": `{nPosts, number} {nPosts, plural,
      one {post}
      other {posts}
    } taggato  con`,
  articles: "Articoli",
  "articles.nPosts": `{nPosts, number} {nPosts, plural,
      one {articolo}
      other {articoli}
    } :`,
  "articles.end": "Fine sezione articoli",
  home: "Casa",
  shop: "Shop",
  biography: "Biografia",
  artworks: "Opere",
  introduction: "Introduzione",
  portfolio: "Portfolio",
  drawing: "Disegno",
  painting: "Pittura",
  "painting-2019": "2019",
  "painting-2018": "2018",
  "painting-oldest": "meno recenti",
  sculpture: "Scultura",
  marble: "Marmo",
  wood: "Legno",
  bronze: "Bronzo",
  "other-materials": "Altri materiali",
  performance: "Performance",
  performance01: "Art is ascent",
  performance02: "L'artista e l'Utopia",
  performance03: "Quadrato nero",
  performance04: "Performance 04",
  "new-media": "New media",
  augmented_reality: "Realtà aumentata",
  interactivity: "Arte interattiva",
  experimental: "Experimental",
  blog: "Blog",
  "blog-message": "Ultime Notizie",
  statement: "Statement",
  "keep-reading": "Continua a leggere →",
  followus: "Segui: ",
  "info-sheet": "Scheda informativa",
  "info-sheet-title": "Titolo: ",
  "info-sheet-year": "Anno: ",
  "info-sheet-technique": "Tecnica: ",
  "info-sheet-dimensions": "Dimensioni: ",
  "find-out-more": "Scopri di più...",
  contact: "Contatti",
  "contact.infos": "Informazioni di contatto",
  "contact.fill-the-form": "Riempi la scheda per inviare un messaggio",
  "contact.name": "Nome",
  "contact.surname": "Cognome",
  "contact.email": "Email",
  "contact.subject": "Soggetto",
  "contact.gender.male": "Maschio",
  "contact.gender.female": "Femmina",
  "contact.enquiry": "Tipo di Richiesta",
  "contact.enquiry.a": "Voglio saperne di più",
  "contact.enquiry.b": "A proposito di arte",
  "contact.enquiry.c": "Inviare un saluto",
  "contact.message": "Messaggio",
  "contact.send": "Invia",
  not_found: "404 - Pagina Non Trovata",
  404: "Sei giunto ad una pagina che non esiste più o hai sbagliato il percorso...",
  copyright: "2019-2022, Walter Perdan - Tutti i diritti riservati.",
  "menu-icon-message": "Scegli una sezione",
  "menu-icon-subtitle": "Sezione opere d'arte",
  "menu-icon-painting": "Pittura",
  "menu-icon-sculpture": "Scultura",
  "menu-icon-performance": "Performance",
  "menu-icon-interactivity": "Interattività",
  sharing: "Condividi nei social media",
  pdf: "Tutti i PDF da scaricare",
  "cookie-message":
    "Questo sito usa i cookie per migliorare l'esperienza utente.",
};
