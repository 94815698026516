module.exports = {
  augmented_reality: [
    "/en/artworks/new-media/augmented-reality/",
    "/it/opere/new-media/realtà-aumentata/",
  ],
  interactivity: [
    "/en/artworks/new-media/interactive-art/",
    "/it/opere/new-media/arte-interattiva/",
  ],
  experimental: [
    "/en/artworks/new-media/experimental/",
    "/it/opere/new-media/experimental/",
  ],
};
