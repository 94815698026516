module.exports = {
  introduction: [
    "/en/artworks/painting/painting-intro/",
    "/it/opere/pittura/pittura-intro/",
  ],
  "painting-2019": ["/en/artworks/painting/2019/", "/it/opere/pittura/2019/"],
  "painting-2018": ["/en/artworks/painting/2018/", "/it/opere/pittura/2018/"],
  "painting-oldest": [
    "/en/artworks/painting/oldest/",
    "/it/opere/pittura/archivio/",
  ],
};
