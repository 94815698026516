module.exports = {
  introduction: [
    "/en/artworks/sculpture/sculpture-intro/",
    "/it/opere/scultura/scultura-intro/",
  ],
  marble: ["/en/artworks/sculpture/marble/", "/it/opere/scultura/marmo/"],
  wood: ["/en/artworks/sculpture/wood/", "/it/opere/scultura/legno/"],
  bronze: ["/en/artworks/sculpture/bronze/", "/it/opere/scultura/bronzo/"],
  "other-materials": [
    "/en/artworks/sculpture/other-materials/",
    "/it/opere/scultura/altri-materiali/",
  ],
};
