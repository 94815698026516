module.exports = {
  selectLanguage: "Select your language",
  tags: "Tags",
  "tags.allTagsLink": "Browse all tags",
  "tags.intro": "Here you can find all the tags",
  "tags.blog.intro": "Here you can find all the blog tags",
  "tags.pages.intro": "Here you can find all the tags in the pages",
  "tags.nPostsTaggedWith": `{nPosts, number} {nPosts, plural,
      one {post}
      other {posts}
    } tagged with`,
  articles: "Articles",
  "articles.nPosts": `{nPosts, number} {nPosts, plural,
      one {article}
      other {articles}
    } :`,
  "articles.end": "End articles section",
  home: "Home",
  shop: "Shop",
  biography: "Biography",
  artworks: "Artworks",
  introduction: "Introduction",
  portfolio: "Portfolio",
  drawing: "Drawing",
  painting: "Painting",
  "painting-2019": "2019",
  "painting-2018": "2018",
  "painting-oldest": "oldest",
  sculpture: "Sculpture",
  marble: "Marble",
  wood: "Wood",
  bronze: "Bronze",
  "other-materials": "Other materials",
  performance: "Performance",
  performance01: "Art is ascent",
  performance02: "L'artista e l'Utopia",
  performance03: "Black square",
  performance04: "Performance 04",
  "new-media": "New media",
  augmented_reality: "Augmented reality",
  interactivity: "Interactive art",
  experimental: "Experimental",
  blog: "Blog",
  "blog-message": "Last News",
  statement: "Statement",
  "keep-reading": "Keep reading →",
  "find-out-more": "Find out more...",
  followus: "Follow Us: ",
  "info-sheet": "Informative sheet",
  "info-sheet-title": "Title: ",
  "info-sheet-year": "Year: ",
  "info-sheet-technique": "Technique: ",
  "info-sheet-dimensions": "Dimensions: ",
  contact: "Contacts",
  "contact.infos": "Contact informations",
  "contact.fill-the-form": "Fill the form to send a message",
  "contact.name": "Name",
  "contact.surname": "Surname",
  "contact.email": "Email",
  "contact.subject": "Subject",
  "contact.gender.male": "Male",
  "contact.gender.female": "Female",
  "contact.enquiry": "Type of Enquiry",
  "contact.enquiry.a": "Need to know more",
  "contact.enquiry.b": "About art",
  "contact.enquiry.c": "Want to say hello",
  "contact.message": "Message",
  "contact.send": "Send",
  not_found: "404 - Page Not Found",
  404: "You are arrived to an unvailable page or you have missed the path...",
  copyright: "2019-2022, Walter Perdan - All rights reserved.",
  "menu-icon-message": "Choose a section",
  "menu-icon-subtitle": "Artworks sections",
  "menu-icon-painting": "Painting",
  "menu-icon-sculpture": "Sculpture",
  "menu-icon-performance": "Performance",
  "menu-icon-interactivity": "Interactivity",
  sharing: "Share on social media",
  pdf: "All PDF Downloads",
  "cookie-message": "This website uses cookies to enhance the user experience.",
};
