module.exports = {
  portfolio: [
    "/en/artworks/contemporary-art-portfolio/",
    "/it/opere/portfolio-arte-contemporanea/",
  ],
  drawing: ["/en/artworks/drawing/", "/it/opere/disegno/"],
  painting: [
    "/en/artworks/painting/painting-intro",
    "/it/opere/pittura/pittura-intro",
  ],
  sculpture: [
    "/en/artworks/sculpture/sculpture-intro",
    "/it/opere/scultura/scultura-intro",
  ],
  performance: [
    "/en/artworks/performances/performances-intro",
    "/it/opere/performances/performances-intro",
  ],
  interactivity: [
    "/en/artworks/new-media/interactive-art/",
    "/it/opere/new-media/arte-interattiva/",
  ],
};
