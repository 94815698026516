module.exports = {
  introduction: [
    "/en/artworks/performances/performances-intro/",
    "/it/opere/performances/performances-intro/",
  ],
  performance01: [
    "/en/artworks/performances/art-is-ascent-performance/",
    "/it/opere/performances/arte-ascent-performance/",
  ],
  performance02: [
    "/en/artworks/performances/artist-utopia-performance/",
    "/it/opere/performances/artista-utopia-performance/",
  ],
  performance03: [
    "/en/artworks/performances/black-square-performance/",
    "/it/opere/performances/quadrato-nero-performance/",
  ],
};
